import React, { useEffect, useState,useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import { forward } from './main_wonder'
import { useSpring, animated } from 'react-spring';
import { useAutoAnimate } from "@formkit/auto-animate/react";
import {Fluence, kras} from '@fluencelabs/js-client'
// import usbSymbol from './usb-symbol.svg'
import {sequence}from '0xsequence'

const ENDPOINT_NODE_JS = 'https://conjurer.today.ngrok.dev'
const ENDPOINT = 'https://708.ehwaz.workers.dev'
const CHAT_STATES: any = {
  0: 'GREETING',
  1: 'SUPPORT',
  2: 'MEDIA_INQUIRY',
  3: 'LOADING_MEDIA',
  4: 'LOADED_MEDIA',
  5: 'LOADING_DEPLOYMENT',
  6: 'DEPLOYED',
  7: 'REPEAT'
}

// const tempFruits = [
//   { name: "Apple", emoji: "🍎" },
//   { name: "Mango", emoji: "🥭" },
//   { name: "Strawberry", emoji: "🍓" },
//   { name: "Orange", emoji: "🍊" },
//   { name: "Peach", emoji: "🍑" }
// ]
// function MyList() {
//   const [animationParent] = useAutoAnimate();
//   const [fruits, setFruits] = useState<any>(tempFruits);

//   const moveUp = (fruit: any) => {
//     // move the fruit up
//     const fruitIndex = fruits.findIndex((f: any) => f.name === fruit.name);
//     if (fruitIndex === 0) return;
//     const newFruits: any = [...fruits];
//     newFruits[fruitIndex] = fruits[fruitIndex - 1];
//     newFruits[fruitIndex - 1] = fruit;
//     setFruits(newFruits);
//   };

//   const moveDown = (fruit: any) => {
//     // move the fruit down
//     const fruitIndex = fruits.findIndex((f: any) => f.name === fruit.name);
//     if (fruitIndex === fruits.length - 1) return;
//     const newFruits: any = [...fruits];
//     newFruits[fruitIndex] = fruits[fruitIndex + 1];
//     newFruits[fruitIndex + 1] = fruit;
//     setFruits(newFruits);
//   };

//   useEffect(() => {

//   }, [fruits])

//   const intervalRef: any = useRef();

//   const shuffleArray = (array: any) => {
//     for (let i = array.length - 1; i > 0; i--) {
//       const j = Math.floor(Math.random() * (i + 1));
//       [array[i], array[j]] = [array[j], array[i]]; // Swap elements
//     }
//     return array;
//   };

//   useEffect(() => {
//     intervalRef.current = setInterval(() => {
//       setFruits((prevFruits: any) => [...shuffleArray([...prevFruits])]);
//     }, 4000);

//     return () => clearInterval(intervalRef.current); // Clear interval on component unmount
//   }, []);

//   return (
//     <article className="panel is-primary" 
//     >
//       <ul className='ul' ref={animationParent} style={{overflowY: 'auto', maxHeight: '400px'}}>

//         {fruits.map((fruit: any, i: any) => (
//           <li
//             key={fruit.name}
//             style={{listStyle: 'none'}}
//             className="panel-block is-active is-flex is-justify-content-space-between"
//           >
//           <div className="flex-container">
//             <div>
//             <animated.div className={`message message-${false ? 'sent' : 'received'}`}>
//               <img width={'100%'} src="https://images.unsplash.com/photo-1714997219788-660af304f464?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyMnx8fGVufDB8fHx8fA%3D%3D"/>
//             </animated.div>
//             </div>
//             <div className="is-flex">
//               <div
//                 onClick={() => moveUp(fruit)}
//                 className={`mr-4 ${
//                   i === 0 ? "has-text-grey-light" : "is-clickable"
//                 }`}
//               >
//                 ▲
//               </div>
//               <div
//                 onClick={() => moveDown(fruit)}
//                 className={`${
//                   i === fruits.length - 1
//                     ? "has-text-grey-light"
//                     : "is-clickable"
//                 }`}
//               >
//                 ▼
//               </div>
//             </div>
//           </div>

//           </li>

//         ))}

//       </ul>
//     </article>
//   );
// }

const AnimatedImageMessage = ({ message, bot, src }: any) => {
  const props = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { tension: 170, friction: 14 }
  });

  return (
    <animated.div style={props} className={`message message-${!bot ? 'sent' : 'received'}`}>
      <img width={'100%'} src={src}/>
    </animated.div>
  );
}

const AnimatedLinkMessage = ({ message, bot, src }: any) => {
  const props = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { tension: 170, friction: 14 }
  });

  return (
    <animated.div style={props} className={`message message-${!bot ? 'sent' : 'received'}`}>
      <div><a href={message} target='_blank'>Here is the link</a>, please save in a safe location</div>
    </animated.div>
  );
}

const AnimatedMessage = ({ message, bot }: any) => {
  const props = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { tension: 170, friction: 14 }
  });

  return (
    <animated.div style={props} className={`message message-${!bot ? 'sent' : 'received'}`}>
      <div>{message}</div>
    </animated.div>
  );
};

let messages: any = []
let memoryURL: any = null

const generate = async (prompt: string) => {
  const data = {
    prompt: prompt,
  };

  const res = await fetch(`${ENDPOINT}/generate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  const json = await res.json()
  console.log(json)
  return json.url
}

const store = async (prompt: string, url: string) => {
  const data = {
    prompt: prompt,
    url: url
  };

  const res = await fetch(`${ENDPOINT}/store`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  const json = await res.json()
  console.log(json)
  return json.tokenID
}

const mint = async (address: string, tokenID: string) => {
  const data = {
    address: address,
    tokenID: tokenID
  };

  try {
    const res = await fetch(`${ENDPOINT_NODE_JS}/mint`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
  
    if(res.status == 400){
      return 'blocked'
    } else {
      console.log(res)
      const json = await res.json()
      console.log(json)
      return true
    }
  }catch(err){
    console.log(err)
  }
}

function App() {
  const [view, setView] = useState(0)
  const [message, setMessage] = useState<any>('')
  const [post, setPost] = useState<any>('')
  const [count, setCount] = useState(0)
  const [chatState, setChatState] = useState<any>('GREETING')
  const [mintTitle, setMintTitle] = useState<any>('<default>')
  const [accountAddress, setAccountAddress] = useState<any>(null)
  const [tokenID, setTokenID] = useState<any>(null)
  const [savedPrompt, setSavedPrompt] = useState<any>(null)

  const props = useSpring({
    from: { transform: 'scale(0)' },
    to: { transform: 'scale(1)' },
    delay: 1000,
    config: { tension: 120, friction: 14 }
  });

  useEffect(() => {
    const rawMessages: any = []
    if(chatState == CHAT_STATES[0] && post == '' && count<1){
        rawMessages.push([
          <animated.div style={props} className='message message-received'>
            <div >{`Hi, would you like to craft a collectible?`}</div>
          </animated.div>
      ])
      messages = rawMessages
      setCount(1)
    } 
  }, [post, accountAddress])

  useEffect(() => {

  }, [post, chatState, savedPrompt])

  const wait = async (ms: any) => {
    return new Promise((res) => setTimeout(res, ms))
  }

  function containsAffirmative(inputString: string) {
      // Define the regular expression for the words
      // The 'i' flag makes the search case-insensitive
      const regex = /\b(yes|ya|yeah|ye|yaya)\b/i;

      // Test if any of the words are in the input string
      return regex.test(inputString);
  }

  function containsNegative(inputString: string) {
      // Define the regular expression for the words
      // The 'i' flag makes the search case-insensitive
      const regex = /\b(no|nah|nope|nay)\b/i;

      // Test if any of the words are in the input string
      return regex.test(inputString);
  }

  function wantsToMintAgain(inputString: string) {
    // Define the regular expression for the phrases indicating interest in minting again
    // The 'i' flag makes the search case-insensitive
    const regex = /\b(again|another|more|mint another|mint again|i want to mint|let's mint|can i mint|one more)\b/i;

    // Test if any of the expressions are in the input string
    return regex.test(inputString);
  }

  useEffect(() => {
    if(post != ''){
      try {
        setTimeout(async (messagesTemp) => {
          console.log('here')
          if(chatState == CHAT_STATES[7] && wantsToMintAgain(post)){
            messages = [...messages, <AnimatedMessage key={messages.length} message={post} />]
            
            await wait(1000)
            messages = [...messages, <animated.div style={props} className='message message-received'>
              <div >{`What would you like the collectible to contain?`}</div>
              </animated.div>
            ]
            setCount(count+1)
            setChatState(CHAT_STATES[1])

          } else if(chatState == CHAT_STATES[0] && containsAffirmative(post)){
              messages = [...messages, <AnimatedMessage key={messages.length} message={post} />]
              setCount(count+1)
              await wait(1000)
              messages = [...messages, <AnimatedMessage bot={true} key={messagesTemp.length} message={'What would you like the collectible to contain?'} />]
              setCount(count+1)
              setChatState(CHAT_STATES[1])
          } else if(chatState == CHAT_STATES[1] && post != ''){
              setSavedPrompt(post)
              messages = [...messages, <AnimatedMessage key={messages.length} message={post} />]
              setCount(count+1)
              await wait(1000)
              messages = [...messages, <AnimatedMessage bot={true} key={messagesTemp.length} message={'One moment please'} />]
              setChatState(CHAT_STATES[2])
              await wait(3000)
              setChatState(CHAT_STATES[3])
              const url = await generate(post)
              memoryURL = url
              messages = [...messages, <AnimatedMessage bot={true} key={messagesTemp.length} message={"How's this"} />]
              await wait(1000)
              
              messages = [...messages, <AnimatedImageMessage src={url} bot={true} key={messagesTemp.length}/>]
              
              await wait(1000)
              messages = [...messages, <AnimatedMessage bot={true} key={messagesTemp.length} message={'Would you like to make the media mintable to you and others?'}/>]
              setChatState(CHAT_STATES[4])
              setCount(count+1)
          } 
          else if(chatState == CHAT_STATES[4] && containsAffirmative(post)){
            messages = [...messagesTemp, <AnimatedMessage key={messages.length} message={post} />]
            setCount(count+1)
            
            await wait(1000) // artificial wait
            messages = [...messages, <AnimatedMessage bot={true} key={messages.length} message={"Storing..."}/>]
            setCount(count+1)
            setChatState(CHAT_STATES[5])
            const tokenIDRes = await store(savedPrompt, memoryURL)
            console.log(tokenIDRes)
            // await wait(4000) // artificial wait
            // messages = [...messages, <AnimatedLinkMessage bot={true} key={messages.length} message={`http://localhost:3001/?tokenID=${tokenIDRes}&contractAddress=${'0x5106c4fc341683a7b617408fe16e91bcf8ad1e4b'}`}/>]
            messages = [...messages, <AnimatedLinkMessage bot={true} key={messages.length} message={`https://www.conjure.today/?tokenID=${tokenIDRes}&contractAddress=${'0x5106c4fc341683a7b617408fe16e91bcf8ad1e4b'}`}/>]
            setCount(count+1)
            setChatState(CHAT_STATES[7])
          }
          else if(chatState == CHAT_STATES[4] && post){
            messages = [...messagesTemp, <AnimatedMessage key={messages.length} message={post} />]
            setCount(count+1)
            await wait(1000)
            const rawMessages = []
            rawMessages.push([
                <animated.div style={props} className='message message-received'>
                  <div >{`Okay, would you like to craft another collectible?`}</div>
                </animated.div>
            ])
            messages = [...messages, ...rawMessages]
            setCount(count+1)
            setChatState(CHAT_STATES[0])

          }
        }, 0, messages)
        setPost('')
        setMessage('')
      }catch(err){
          console.log(err)
      }
    }
  }, [post, message, chatState])

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById('messagesEnd');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 10)

  }, [messages, count, view])

  const handleKeyDown = (event: any) => {
    // Check if the Enter key was pressed without holding down the Shift key
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent the default behavior (new line)
      setPost(message);setMessage('');setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 0)
    }
  };

  function getTokenIDFromURL() {
    // Parsing the URL search query string
    const queryParams = new URLSearchParams(window.location.search);
    // Getting the 'tokenID' parameter from the query string
    const tokenID = queryParams.get('tokenID');
    return tokenID;
  }

  const [txHash, setTxHash] = useState<any>(null)

  useEffect(() => {
    if(getTokenIDFromURL()){
      setView(4)
      setTokenID(getTokenIDFromURL())
    }
  }, [])

  sequence.initWallet('AQAAAAAAAExvUQz5o6U0PkPT1uU_gZUNdEA', {defaultNetwork: 'arbitrum-sepolia'})
  
  const signIn = async () => {
    const wallet = sequence.getWallet()
    const details = await wallet.connect({app:'conjure.today'})

    if(details.connected){
      setAccountAddress(details.session?.accountAddress)
      setView(1)
    }
  }

  const [isMinting, setIsMinting] = useState<any>(false)
  const [blocked, setBlocked] = useState<any>(false)

  useEffect(() => {

  }, [isMinting, blocked])

  const mintFromNode = async () => {
    const wallet = sequence.getWallet()
    const details = await wallet.connect({app:'conjure.today'})

    if(details.connected){
      setIsMinting(true)

      const res: any = await mint(details.session?.accountAddress!, getTokenIDFromURL()!)
      if(res.status == 400){
        setIsMinting(false)
        setBlocked(true)
      }else {

      setIsMinting(false)
      
      console.log(res)
      setTxHash('')
    }

    }
  }

  useEffect(() => {

  }, [txHash])

  return (
    <div className="App">
        
        {view < 4 && <div style={{
            width: 'fit-content',
            position: 'fixed',  // Fixes the position relative to the viewport
            left: '50%',        // Moves the left edge to the middle of the screen
            transform: 'translateX(-50%)',  // Corrects the centering by moving left by half the width
            top: '30px'         // Positioned 30px from the top
          }}>
            {view > 0 && <p>🪄</p>}
            {/* <img src={'https://usb.ngrok.app/%E2%99%A6/metadata/gen_ai/usb-symbol.svg'} style={{opacity: "0.4"}}/> */}
            {/* 
            <span className='span-tabs' onClick={() => setView(1)} style={{
                borderBottom: view === 1 ? '1px solid aqua' : 'none'  
            }}>channel</span>

            <span className='span-tabs' onClick={() => setView(2)} style={{
                borderBottom: view === 2 ? '1px solid aqua' : 'none' 
            }}>gallery</span>

            <span className='span-tabs' onClick={() => setView(3)} style={{
                borderBottom: view === 3 ? '1px solid aqua' : 'none' 
            }}>voting</span>*/}
        </div>} 
        <br/>
        <br/>
        <br/>
        <br/>
        { 
            view == 0
          ?
          <>
            <br/>
            <p>🪄</p>
            <p><b>conjure.today</b></p>
            <p>conjure AI collectibles</p>
            <p>with a network bunch</p>
            <br/>
            <br/>
            <button className='sign-in' onClick={() => signIn()} style={{background: 'aqua', borderRadius: '30px', width: '100px', height: '35px', border:'0px', fontFamily: 'Inconsolata, monospace', cursor: 'pointer', color: 'grey'}}>sign in</button>
            <br/>
            <br/>
            {/* <div className="network-logos">
              <img style={{padding: '5px'}} src="https://assets.sequence.info/images/networks/small/8453.webp"></img>
              <img style={{padding: '5px'}} src="https://assets.sequence.info/images/networks/small/43114.webp"></img>
              <img style={{borderRadius: '20px', padding: '5px'}} src="https://assets.sequence.info/images/networks/small/660279.webp"></img>
            </div> */}
          </>
          :
            view == 1 
          ? 
            <>
            <div className='messenger'>
              <div className='messages'>
              {messages}
              <div id='messagesEnd' />
              </div> 
              <br/>
              <div className="flex-container">
                <textarea onKeyDown={handleKeyDown} style={{marginRight: '17px'}} onChange={(evt: any) => setMessage(evt.target.value)} value={message} ></textarea>
                <Button size="xl" type="submit" variant="primary" message={message} setMessage={setMessage} setPost={setPost}>
                  Send
                </Button>
              </div>
            </div>
            </>
          : view == 2 ? 
          <>
            <p>no collectibles</p>
          </>
          :
            view == 3 ?
          <>
          {/*<MyList/>*/}

          </>
          : <>
            <img src={`https://metadata.sequence.app/projects/19567/collections/269/tokens/${tokenID}/image.png`} width={'300px'} height={'300px'}/>
            <br/>
            <br/>
            {
            txHash == null 
            ? 
            isMinting
            ?
              <div className="loader"></div>
            :
             <button style={{background: 'aqua', borderRadius: '30px', width: '150px', height: '35px', border:'0px', fontFamily: 'Inconsolata, monospace', cursor: 'pointer', color: 'grey'}} onClick={() => mintFromNode()}>{`sign in & mint a network bunch`}</button> : <p>minted 3/3 networks</p>
             }
             {
              blocked && <p>tokenID blocked</p>
             }
             <br/>
             <br/>
             <div className="network-logos-mint">
              <img style={{padding: '5px'}} src="https://assets.sequence.info/images/networks/small/8453.webp"></img>
              <img style={{padding: '5px'}} src="https://assets.sequence.info/images/networks/small/43114.webp"></img>
              <img style={{borderRadius: '30px', padding: '5px'}} src="https://assets.sequence.info/images/networks/small/660279.webp"></img>
            </div>
          </>
        }
    </div>
  );
}

function Button({
  setMessage,
  message,
  setPost,
  children,
  id,
  type = "button",
  size = "default",
  variant = "default"
}: any) {
  return (
    <button
      id={id}
      className={`button ${variant ? `button--${variant}` : ""} ${
        size ? `button--size-${size}` : ""
      }`}
      type={type}
      onClick={() => {setPost(message);setMessage('');setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 0)}}
    >
      <span className="button__content">{children}</span>
    </button>
  );
}

export default App;